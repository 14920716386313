import { $post } from '@/api/http.js'
import { useRoute } from "vue-router";
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import router from '@/router'
class Live{
    getRouterQuery(){
        return useRoute().query
    }
    
    getLiveList(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if(extra.uid ) param.uid = extra.uid
            if(extra.title ) param.title = extra.title
            if(extra.title ) param.key = extra.title
            if(extra.category_id ) param.category_id = extra.category_id
            if(extra.open_status == 2 || extra.open_status == 1 ){
                param.open_status = extra.open_status == 1 ? 1 :0
            }
        }
        common.getDataList("getLiveList",param).then(res=>{
            fn(res)
        })
    }
    /**
     * 切换监控上下架状态
     * @param {number} status 
     * @param {object} fn 
     */
    changeLivePutaway(status,fn){
        tool.confirm(`确认${status == 1 ?'上架':'下架'}所有监控吗?`).then(()=>{
            $post('offShelfLive',{open_status:status}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 新增或编辑监控信息
     * @param {object} param 
     */
    addOrEditLive(param){
        if( !tool.returnMessage(param.category_id,"请选择监控分类")) return
        if( !tool.returnMessage(param.title,"请输入监控标题")) return
        if( !tool.returnMessage(param.live_uid,"请输入监控UID")) return
        if( !param.id ) delete param.id
        //设置默认封面图
        if( param.cover == "" ) param.cover = tool.getStaticSrc("admin/common/live-cover.jpg")
        $post("createOrUpdateLive",param).then(res=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取监控详情
     * @param {number} id 
     * @param {array} keys  筛选字段 
     * @param {function} fn 
     */
    getLiveDetail(id,keys,fn){
        $post('getLiveDetail',{id}).then(res=>{
            if( keys ) {
                fn(tool.filterForm(keys,res.data))
                return
            }
            fn(res.data)
        })
    }

    /**
     * 获取国标设备详情信息
     * @param {object}} data 请求参数
     * @returns 
     */
    getGbDeviceDetail(data) {
        return new Promise((resolve,reject)=>{
            $post("devicesDetails",data).then(res=>{
                if( res.errorCode == 0 ) resolve(res.data)
            }).catch(()=>{})
        })
    }
    /**
     * 获取国标设备的网关信息
     * @param {number}} id 设备ID
     * @returns 
     */
    getNameSpacesInfo(id){
        return new Promise (reslove=>{
            let param = { id}
            $post("namespacesInfo",param).then(res=>{
                if( res.errorCode == 0 ) reslove(res.data)
            })
        })    
    }
    
    /**
     * 获取设备流信息
     * @param {number} device_id 设备id
     * @param {string}} channel_id  通道id
     * @returns 
     */
    getStreamInfo(device_id,channel_id){
        return new Promise(reslove=>{
            let params = { id:device_id }
            if( channel_id ) params.streams = channel_id
            $post("streaminfo",params).then(res=>{
                reslove(res.data)
            })
        })
    }

    /**
     * 启动或停止拉流
     * @param {string} type      start启动 stop停止
     * @param {number} device_id 设备id
     * @param {string} channel_id 通道id
     * @returns 
     */
    startOrStopPullStreams(type,device_id,channel_id){
        return new Promise(reslove=>{
            let params = { id:device_id } , api = ""
            if( channel_id ) params.channels = channel_id
            if(type == 'stop') api = "deviceStop"
            if(type == 'start') api = "deviceStart"
            $post(api,params).then(res=>{
                reslove(res.data)
            })
        })
    }
    /**
     * 设备方向控制
     * @param {number} device_id    设备id
     * @param {string} cmd          方向
     * @param {number} speed        速度
     * @param {string} channel_id   通道ID
     * @returns 
     */
    directionControl(device_id,cmd,speed,channel_id){
        return new Promise(reslove=>{
            let params = { id:device_id,cmd,speed }
            if( channel_id ) params.channels = channel_id
            $post("deviceControl",params,true,2,false).then(res=>{
                // let params_stop = {...params}
                // params_stop.cmd = 'stop'
                // $post("deviceControl",params_stop).then(()=>{})
                // reslove(res.data)
            })
        })
    }
    /**
     * 设备变焦操作
     * @param {number} device_id    设备id
     * @param {string} cmd          方向
     * @param {number} speed        速度
     * @param {string} channel_id   通道ID
     * @returns 
     */
    changeFocus(device_id,cmd,speed,channel_id){
        let params = { id:device_id,cmd,speed }
        if( channel_id ) params.channels = channel_id
        $post("devicefocus",params,true,2,false).then(res=>{
            // let params_stop = {...params}
            // params_stop.cmd = 'stop'
            // $post("deviceControl",params_stop).then(()=>{})
            // reslove(res.data)
        })
    }
    /**
     * 设备光圈操作
     * @param {number} device_id    设备id
     * @param {string} cmd          方向
     * @param {number} speed        速度
     * @param {string} channel_id   通道ID
     * @returns 
     */
    changeAperture(device_id,cmd,speed,channel_id){
        let params = { id:device_id,cmd,speed }
        if( channel_id ) params.channels = channel_id
        $post("deviceiris",params,true,2,false).then(res=>{
            // let params_stop = {...params}
            // params_stop.cmd = 'stop'
            // $post("deviceControl",params_stop).then(()=>{})
            // reslove(res.data)
        })
    }
    /**
     * 获取预设位置信息
     * @param {number}} device_id 设备id
     * @param {string} channel_id 通道id
     * @returns 
     */
    getPrePosition(device_id,channel_id){
        return new Promise(resolve=>{
            let param = { id:device_id}
            if(channel_id ) param.channels = channel_id
            $post("devicepresetsList",param).then(res=>{
                resolve(res.data)
            })
        })
    }
    /**
     * 预设位置保存
     * @param {number}} device_id 设备id
     * @param {string} cmd  操作类型
     * @param {string} name  cmd=set:预设位置名称 cmd=remove /goto  预设位置id
     * @param {string} channels  通道id
     * @returns 
     */
    savePositon(device_id,cmd,name,channels=null){
        if(cmd == "set" && name == "" ){
            tool.message("请填写名称","warning")
            return
        }
        return new Promise(reslove=>{
            let param = { id:device_id,cmd}
            //预设位置添加
            if( cmd == 'set') param.name = name
            //预设位置删除/调用
            if( cmd == 'remove' || cmd == 'goto') param.presetId = name
            if( channels ) param.channels = channels
            $post("devicepresets",param).then(()=>{
                if( cmd == 'set') tool.message("保存成功","success")
                if( cmd == 'remove') tool.message("删除成功","success")
                reslove(true)
            })
        })
    }
    /**
     * 设备同步到监控
     * @param {number} device_id 设备id
     * @param {string} channel_id 设备通道id
     */
    deviceToLive(device_id,channel_id){
        tool.confirm("确认同步设备到监控吗？").then(()=>{
            let param = { id:device_id}
            if(channel_id ) param.streams = channel_id
            $post("synchronousMonitoring",param).then(()=>{
                tool.message("同步成功","success")
            })
        }).catch(()=>{})
    }
    /**
     * 获取通道列表
     * @param {number} device_id 
     * @returns 
     */
    getChannel(device_id){
        return new Promise(reslove=>{
            $post("getdevicechannels",{id:device_id}).then(res=>{
                reslove(res.data)
            })
        })
    }
    /**
     * 获取巡航组信息
     * @param {number} cloud_id 云端id
     * @param {string} channel_id 通道id
     * @returns 
     */
    getCruisegroups(cloud_id,channel_id){
        return new Promise(reslove=>{
            let param = {id:cloud_id}
            if( channel_id ) param.streams = channel_id
            $post("getCruisegroups",param).then(res=>{
                reslove(res.data)
            })
        })
    }
    /**
     * 添加巡航组信息
     * @param {object} data 
     * @returns 
     */
    addCruisegroups(data){
        return new Promise(reslove=>{
            $post("cruisegroups",data).then(()=>{
                tool.message("保存成功","success")
                reslove(true)
            })
        })
    }
    /**
     * 执行/调用巡航组
     * @param {*} device_id     设备id
     * @param {*} group_id      巡航组id
     * @param {*} channel_id    通道id
     */
    callCruise(device_id,group_id,channel_id){
        let param = { id:device_id , groupId:group_id }
        if( channel_id ) param.streams = channel_id
        $post("startCruisegroups",param).then(()=>{
            tool.message("调用成功","success")
        })
    }
    /**
     * 删除巡航组
     * @param {*} device_id     设备id
     * @param {*} group_id      巡航组id
     * @param {*} channel_id    通道id
     */
    deleteCruise(device_id,group_id,channel_id){
        return new Promise(resolve=>{
            let param = { id:device_id , groupId:group_id }
            if( channel_id ) param.streams = channel_id
            $post("deleteCruisegroups",param).then(()=>{
                tool.message("删除成功","success")
                resolve(true)
            })
        })
    }
    /**
     * 获取监控分类列表信息
     * @param {number} page 当前页
     * @param {number} limit 每页显示数量
     * @param {onject} extra 额外的查询参数
     * @returns 
     */
    getLiveTypeList(page=1,limit=10,extra) {
        return new Promise(reslove=>{
            let data = { page,limit }
            if( extra && extra.key ) data.key = extra.key
            common.getDataList("getLiveCategoryList",data).then(res=>{
                reslove(res)
            })
        })
    }
    /**
     * 删除监控分类
     * @param {number} id 
     * @returns 
     */
    deleteLiveType(id){
        if( !id || id.length == 0 ){
            tool.message("请选择要删除的监控分类信息","warning")
            return
        }
        return new Promise(reslove=>{
            common.deleteDataList(id,10,"确认删除该监控分类吗?").then(res=>{
                reslove(res)
            })
        })
    }
    
    /**
     * 保存监控分类信息
     * @param {object} data
     * @returns 
     */
    createOrEditLiveType(data){
        if( data.name == ''){
            tool.message("请填写分类名称","warning")
            return
        }
        return new Promise(reslove=>{
            if( !data.id ) delete data.id
            $post("createOrUpdateLiveCategory",data).then(res=>{
                tool.message("保存成功","success")
                reslove(true)
            })
        })
    }
    /**
     * 分配监控
     * @param {string} type   操作类型 adopt=认养 goods=商品
     * @param {array} ids     设置的值
     * @param {object} extra 额外的请求参数
     * @param {object} fn 
     */
    allocationLive(type,ids,extra,fn) {
        let param = { ...extra } ,api = ""
        if( type == 'adopt' ){      //认养分配监控
            param.live_ids = ids;
            api="setLiveByDetail";
        }

        if( type == 'goods' ){
            param = {
                goods:{...extra,live_id:ids},
                is_live:1
            }
            api="createOrUpdateGoods";
        }
        $post(api,param).then(res=>{
            tool.message("设置成功")
            fn(res.data)
        })
    }
    /**
     * 获取物联网设备
     * @param {number} shop_id 
     * @param {boolean} is_cache 
     * @param {function} fn 
     */
    open_getThingHostList(shop_id,is_cache=true,fn){
        $post('open_getThingHostList',{shop_id,is_cache}).then(result=>{
            fn(result.data)
        })
    }

    /**
     * 获取国标设备列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getGbDevice(page,limit,extra,fn){
        let param = { page,limit}
        common.getDataList("deviceList",param).then(res=>fn(res))
    }

    /**
     * 添加国标设备
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditGbDevice(param,fn){
        if( !tool.returnMessage(param.desc,"请输入设备名称")) return
        $post("addDevice",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 删除国标设备
     * @param {number} id 
     * @param {function} fn 
     */
    deleteGbDevice(id,fn){
        tool.confirm("确认删除设备信息?").then(()=>{
            $post("deleteDevices",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 坤典云授权
     * @param {object} param 
     * @param {function} fn 
     */
    kdCloudAuth(param,fn){
        if( param.name == '' || param.password == ''){
            tool.message("请输入登录账号信息","warning")
            return
        }
        $post("open_shopLoginOrRegis",param).then(res=>fn(res.data))
    }

    /**
     * 获取坤典云统计信息
     * @param {function} fn 
     */
    getKdCloudInfos(fn){
        $post("programDeviceInfo").then(res=>fn(res.data))
    }

    /**
     * 获取短信发送记录
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getShortRecord(page,limit,fn){
        let param = { page,limit}
        common.getDataList("mySmsLogList",param).then(res=>fn(res))
    }

    /**
     * 七牛云设备流量统计
     * @param {function} cb 
     */
    getQiniuFlowTotal(cb){
        $post("myQiniuFlow").then(res=>cb(res.data))
    }
}

const liveModel = new Live()
export default liveModel
