<template>
	<div id="homePage">
		<div class="footer-menu-btn">
            <a-space>
                <a-button @click="$router.go(-1)">返回</a-button>
                <a-button type="primary" @click="saveHomePage(is_default)">保存</a-button>
                <a-button v-if="is_default==0" type="primary" @click="saveHomePage(1)">保存并设置为默认首页</a-button>
            </a-space>
		</div>
		<div class="custom-storepage" :style="{height:(screenHeight - 40)+'px'}">
		    <!-- 选项 -->
		    <div class="storepage-option">
		        <div class="storepage-option-title">
		            <div :class="type == 1?'optionactive':''" @click="option(1)">基础组件</div>
		            <div :class="type == 2?'optionactive':''" @click="option(2)">营销功能</div>
		        </div>
		        <!-- 基础组件 -->
		        <div class="basic-components">
		            <div v-if="type == 1">
		                <div class="basic-components-title">基础组件</div>
		                <div class="basic-components-ul">
		                    <div class="basic-components-ul-li"  @click="pageTitle.is_show_nav=true">
		                        <img src="@/assets/img/diy/a.png" alt="">
		                        <div>顶部导航</div>
		                    </div>
							
							<div class="basic-components-ul-li"  @click="addElement('kd-hotarea')">
							    <img src="@/assets/img/diy/i.png" alt="">
							    <div>图片热区</div>
							</div>
		                    <div class="basic-components-ul-li"  @click="addElement('slide')">
		                        <img src="@/assets/img/diy/b.png" alt="">
		                        <div>轮播广告</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('navigation')">
		                        <img src="@/assets/img/diy/c.png" alt="">
		                        <div>宫格导航</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('advert')">
		                        <img src="@/assets/img/diy/d.png" alt="">
		                        <div>广告位</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('blank')">
		                        <img src="@/assets/img/diy/e.png" alt="">
		                        <div>辅助空白</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('headline')">
		                        <img src="@/assets/img/diy/f.png" alt="">
		                        <div>标题</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('lines')">
		                        <img src="@/assets/img/diy/g.png" alt="">
		                        <div>分割线</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('glossarys')">
		                        <img src="@/assets/img/diy/h.png" alt="">
		                        <div>图文集</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('photo')">
		                        <img src="@/assets/img/diy/i.png" alt="">
		                        <div>图片列表</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('weather')">
		                        <img src="@/assets/img/diy/k.png" alt="">
		                        <div>天气</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('information')">
		                        <img src="@/assets/img/diy/m.png" alt="">
		                        <div>资讯</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('goods')">
		                        <img src="@/assets/img/diy/l.png" alt="">
		                        <div>商品列表</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('kd-home-top')">
		                        <img src="@/assets/img/diy/n.png" alt="">
		                        <div>定制顶部</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('kd-store')">
		                        <img src="@/assets/img/diy/z.png" alt="">
		                        <div>店铺展示</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('kd-goods-card')">
		                        <img src="@/assets/img/diy/p.png" alt="">
		                        <div>商品卡片</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('kd-assist-one')">
		                        <img src="@/assets/img/diy/u.png" alt="">
		                        <div>辅助组件</div>
		                    </div>
		                    <div class="basic-components-ul-li"  @click="addElement('kd-video')">
		                        <img src="@/assets/img/diy/w.png" alt="">
		                        <div>视频组件</div>
		                    </div>
							<div class="basic-components-ul-li"  @click="addElement('kd-official')">
							    <img src="@/assets/img/diy/u.png" alt="">
							    <div>公众号展示</div>
							</div>
							<div class="basic-components-ul-li"  @click="addElement('kd-device')">
							    <img src="@/assets/img/diy/u.png" alt="">
							    <div>物联网设备</div>
							</div>
							<div class="basic-components-ul-li"  @click="addElement('kd-popup-advert')">
							    <img src="@/assets/img/diy/u.png" alt="">
							    <div>弹出层</div>
							</div>
		                </div>
		            </div>
		            <div v-if="type == 2">
		                <div class="basic-components-title">营销功能</div>
		                <div class="basic-components-ul">
		                    <div class="basic-components-ul-li"  @click="addElement('coupon')">
		                        <img src="@/assets/img/diy/j.png" alt="">
		                        <div>优惠券</div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
		
		    <!-- 手机显示 -->
		    <div class="mianWrapper">
		        <div class="wxHd"></div>
		        <!-- 导航栏 -->
				<div v-if="pageTitle.is_show_nav">
					<model-item temp-type="kd-nab-bar" :infos="pageTitle" @changeCurrent="changeCurrentItem"></model-item>
				</div>
				<div :ref="myPage" class="activeWrapper" :style="{height:(screenHeight - 103)+'px'}">
					<div class="pre-popup-advert" v-if="show.popupAdvert && show.popupAdvertData">
						<div>
							<div class="pa-mask"></div>
							<img class="pa-img" :src="show.popupAdvertData.cover" alt="">	
							<div class="pa-close"><i class="ri-close-fill ri-lg"></i></div> 
						</div>
					</div>
					<div v-else>
						<draggable v-model="list" :disabled="show.hotAreaEdit" tag="transition-group" animation="500" item-key="id" :component-data="{name:'fade'}">
							<template #item="{element}">
								<div @click="selectCurrentType($event,element.id)" 
									class="activeWrapper_li" 
									:class="{activeBorder:currentId===element.id}" 
									ref="selectDom">
									<i class="ri-close-circle-fill deleteDom" @click.stop="showDeleteWrapper(element.id)"></i>
									
									<model-item :temp-type="element.type" :infos="element"></model-item>
									<custom-store v-if="element.type == 'kd-store'" :infos="element"></custom-store>
									<custom-coupon v-if="element.type == 'coupon'" :infos="element"></custom-coupon>
									<custom-goods v-if="element.type == 'goods'" :infos="element"></custom-goods>
									<custom-home-top
										:bg="element.bg"
										:list="element.list"
										v-if="element.type === 'kd-home-top'">
									</custom-home-top>
									<custom-hot-area 
										v-if="element.type == 'kd-hotarea'" 
										:infos="element" 
										:show-draw="show.hotAreaEdit" 
										@finishDraw="e=>element.list =e">
									</custom-hot-area>
								</div>
							</template>
						</draggable>
					</div>	
					
				</div>
			</div>
		    <!-- 右边设置 -->
		    <div class="edditWrapper" :style="{marginTop: marginEdit +'px'}">
		        <!-- <GeminiScrollbar class='scroll-bar' :style="{height:(screenHeight - 60)+'px'}"> -->
		            <div class="banner_container" v-if="currentId==0">
		                <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
							<a-form-item label="开启">
								<a-switch v-model:checked="pageTitle.is_show_nav"></a-switch>
							</a-form-item>
							<a-form-item label="页面标题">
								<a-input v-model:value="pageTitle.title"></a-input>
							</a-form-item>
							<a-form-item label="背景颜色">
								<kd-color v-model="pageTitle.bgColor"></kd-color>	
							</a-form-item>
							<a-form-item label="文字颜色">
								<a-radio-group v-model:value="pageTitle.fontColor">
									<a-radio value="#fff">白色</a-radio>
									<a-radio value="#000">黑色</a-radio>
								</a-radio-group>
							</a-form-item>
							<a-form-item label="是否允许返回">
								<a-switch v-model:checked="pageTitle.isNavBack"></a-switch>
							</a-form-item>
						</a-form>
		            </div>
		
		            <!-- 定制顶部 -->
		            <div  class="banner_container" v-for="(item,index) in list" :key="index" >
		               <div v-if="'kd-home-top' == currentType && item.id==currentId" >
		                    <div class="commonpent-title">定制顶部</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bg"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="添加轮播图">

		                        </a-form-item>
		                    </a-form>
		
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div class="data-item">
											<div class="item-img" style="width: 130px;">
												<img :src="element.url" alt="" style="width: 130px;">
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="element.url" @change="e=>element.url = e"></kd-img-select>
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												<span v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</span>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
											<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该轮播图吗？')"></i>
										</div>
									</template>
								</draggable>
		                        <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                    </div>
		                </div>
		
		                <!-- 轮播图 -->
		                <div v-if="'slide'==currentType && item.id==currentId">
		                    <div class="commonpent-title">轮播图</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="轮播样式">
									<a-radio-group v-model:value="item.slide_type">
										<a-radio value="1">全屏限高轮播</a-radio>
										<a-radio value="2">卡片式轮播</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="焦点颜色">
									<a-input v-model:value="item.focusColor">
										<template #addonAfter>
											<kd-color v-model="item.focusColor"></kd-color>	
										</template>
									</a-input>
		                        </a-form-item>
		                        <a-form-item label="显示焦点">
									<a-radio-group v-model:value="item.focus">
										<a-radio :value="true">显示</a-radio>
										<a-radio :value="false">不显示</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="轮播高度">
									 <a-slider id="slide-slider" v-model:value="item.swiperHeight" :max="375" />
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div class="data-item">
											<div class="item-img" style="width: 130px;">
												<img :src="element.url" alt="" style="width: 130px;">
											</div>
											<div class="input-item">
												<div class="mb12">
													<kd-img-select :show-img="false" :src="element.url" @change="e=>element.url = e"></kd-img-select>
												</div>	
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												
												<span v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</span>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
											<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该轮播图吗？')"></i>
										</div>
									</template>
								</draggable>
								
		                        <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                    </div>
		                </div>
		
		                <!-- 宫格导航 -->
		                <div v-if="'navigation'==currentType&& item.id==currentId">
		                    <div class="commonpent-title">宫格导航</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="每行显示">
									<a-radio-group v-model:value="item.column">
										<a-radio :value="2">2</a-radio>
										<a-radio :value="3">3</a-radio>
										<a-radio :value="4">4</a-radio>
										<a-radio :value="5">5</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="圆角幅度">
		                            <a-slider v-model:value="item.radius" :max="50"></a-slider>
		                        </a-form-item>
		                        <a-form-item label="文字颜色">
									<kd-color v-model="item.fontColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="字体大小">
									<a-radio-group v-model:value="item.fontSize">
										<a-radio :value="12">小</a-radio>
										<a-radio :value="14">中</a-radio>
										<a-radio :value="16">大</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div class="data-item">
											<div class="item-img">
												<img :src="element.url" alt="">
												<div class="txt">
													<kd-img-select :custom="true" :src="element.url" @change="e=>element.url=e">
														<span>选择图片</span>
													</kd-img-select>
												</div>
											</div>
											<div class="input-item">
												<a-input class="mb12" addon-before="文字" v-model:value="element.title"></a-input>
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												<template v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</template>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
											<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该宫格导航吗？')"></i>
										</div>
									</template>
								</draggable>
		                        <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                    </div>
		                </div>
		
		                <!-- 广告图 -->
		                <div v-if="'advert'==currentType&& item.id==currentId">
		                    <div class="commonpent-title">广告图</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="选择样式">
									 <a-radio-group v-model:value="item.selectType" @change="changeAdvert(index)">
									    <a-radio :value="1">样式一</a-radio>
									    <a-radio :value="2">样式二</a-radio>
									    <a-radio :value="3">样式三</a-radio>
									    <a-radio :value="4">样式四</a-radio>
									    <a-radio :value="5">样式五</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element}">
										<div class="data-item">
											<div class="item-img">
												<img :src="element.url" alt="">
												<div class="txt">
													<kd-img-select :custom="true" :src="element.url" @change="e=>element.url =e">
														选择图片	
													</kd-img-select>
												</div>
											</div>
											<div class="input-item">
												<kd-img-select :show-img="false" :src="element.url" @change="e=>element.url =e"></kd-img-select>
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												<template v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</template>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
										</div>
									</template>
								</draggable>
		                        
		                    </div>
		                </div>
		
		                <!-- 标题 -->
		                <div v-if="'headline'==currentType&& item.id==currentId">
		                    <div class="commonpent-title">标题</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="文字颜色">
									<kd-color v-model="item.fontColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="标题文字">
		                            <a-input v-model:value="item.text"></a-input>
		                        </a-form-item>
		                        <a-form-item label="选择图标">
									<com-reminxi-icon :value="item.icon" @changeValue="(e)=>{item.icon = e}"></com-reminxi-icon>
		                        </a-form-item>
		                        <a-form-item label="链接地址">
									<div class="mb12">
										<com-select-link v-model="item.path" @changeJump="e=>item.jump = e"></com-select-link>
									</div>
									<div v-if="item.jump == 2">
										<a-input v-model:value="item.appid" placeholder="小程序appid"></a-input>
										<a-input v-model:value="item.mini_path" placeholder="小程序打开页面"></a-input>
									</div>
									<span v-if="item.jump == 3">
										<a-input v-model:value="item.path" placeholder="电话号码"></a-input>
									</span>
								 </a-form-item>
		                        <a-form-item label="对齐方式">
									<a-radio-group v-model:value="item.align">
										<a-radio value="left">居左</a-radio>
										<a-radio value="center">居中</a-radio>
										<a-radio value="right">居右</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="文字大小">
		                            <a-slider v-model:value="item.fontSize" :max="30" :min="10"></a-slider>
		                        </a-form-item>
		                        <a-form-item label="上下边距">
		                            <a-slider v-model:value="item.padTb" :max="30"></a-slider>
		                        </a-form-item>
		                        <a-form-item label="左右边距">
		                            <a-slider v-model:value="item.padLr" :max="30"></a-slider>
		                        </a-form-item>
		                    </a-form>
		                </div>
		
		                <!-- 辅助空白 -->
		                <div v-if="'blank'==currentType&& item.id==currentId">
		                    <div class="commonpent-title">辅助空白</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="空白高度">
		                            <a-slider v-model:value="item.height" :max="200"></a-slider>
		                        </a-form-item>
		                    </a-form>
		                </div>
		
		                <!-- 分割线 -->
		                <div v-if="'lines'==currentType&& item.id==currentId">
		                    <div class="commonpent-title">分割线</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="样式选择">
									<a-radio-group v-model:value="item.styles">
										<a-radio value="solid">实线</a-radio>
										<a-radio value="dashed">虚线</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="线条颜色">
									<kd-color v-model="item.lineColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="线条高度">
		                            <a-slider v-model:value="item.lineHeight" :max="20"></a-slider>
		                        </a-form-item>
		                        <a-form-item label="上下边距">
		                            <a-slider v-model:value="item.height" :max="30"></a-slider>
		                        </a-form-item>
		                    </a-form>
		                </div>
		
		                <!-- 图文集 -->
		                <div v-if="'glossarys'==currentType && item.id==currentId">
		                    <div class="commonpent-title">图文集</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="样式选择">
									<a-radio-group v-model:value="item.styles">
										<a-radio :value="1">样式一</a-radio>
										<a-radio :value="2">样式二</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="标题字号">
									<a-radio-group v-model:value="item.titleSize">
										<a-radio :value="14">小</a-radio>
										<a-radio :value="16">中</a-radio>
										<a-radio :value="18">大</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="标题颜色">
									<kd-color v-model="item.titleColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div class="data-item">
											<div class="item-img">
												<img :src="element.url" alt="">
												<kd-img-select :custom="true" :src="element.url" @change="e=>element.url= e">
													<div class="txt">选择图片</div>
												</kd-img-select>
											</div>
											<div class="input-item">
												<a-input  placeholder="标题" v-model:value="element.title"> </a-input>
												<a-input  placeholder="图文内容" v-model:value="element.innerText"> </a-input>
												
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												<template v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</template>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
											<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该图文集吗？')"></i>
										</div>
									</template>
								</draggable>
		                        
		                        <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                    </div>
		                </div>
		
		                <!-- 图片列表 -->
		                <div v-if="'photo'==currentType && item.id==currentId">
		                    <div class="commonpent-title">图片列表</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="样式选择">
									<a-radio-group v-model:value="item.styles">
										<a-radio :value="1">样式一</a-radio>
										<a-radio :value="2">样式二</a-radio>
										<a-radio :value="3">样式三</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="图片排版">
									<a-radio-group v-model:value="item.column" @change="changePhotoColimn(index)">
									    <a-radio :value="1">1</a-radio>
									    <a-radio :value="2">2</a-radio>
									    <a-radio :value="3">3</a-radio>
									    <a-radio :value="4">4</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="圆角比例">
		                            <a-slider v-model:value="item.radius" :max="50"></a-slider>
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div class="data-item">
											<div class="item-img">
												<img :src="element.url" alt="">
												<kd-img-select :custom="true" :src="element.url" @change="e=>element.url = e">
													<div class="txt">选择图片</div>
												</kd-img-select>
											</div>
											<div class="input-item">
												<a-input placeholder="标题" v-model:value="element.title"> </a-input>
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												<template v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</template>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
											<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该图片吗？')"></i>
										</div>
									</template>
								</draggable>
		                        
		                        <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                    </div>
		                </div>
		
		                <!-- 商品列表 -->
		                <div v-if="'goods'==currentType && item.id==currentId">
		                    <div class="commonpent-title">商品列表</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="列表样式">
									<a-radio-group v-model:value="item.listType">
										<a-radio :value="1">样式一</a-radio>
										<a-radio :value="2">样式二</a-radio>
										<a-radio :value="3">样式三</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="商品样式" v-if="item.listType == 2">
									<a-radio-group v-model:value="item.boxshadow">
										<a-radio :value="1">卡片投影</a-radio>
										<a-radio :value="2">白底描边</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="圆角比例">
		                            <a-slider v-model:value="item.radius" :max="10"></a-slider>
		                        </a-form-item>
		                        <a-form-item label="背景颜色">
									<kd-color v-model="item.bgColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="商品名称">
		                            <a-switch v-model:checked="item.isShopname"></a-switch>
		                        </a-form-item>
		                        <a-form-item label="名称颜色" v-if="item.isShopname">
									<kd-color v-model="item.fontColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="商品价格">
		                            <a-switch v-model:checked="item.isPrice"></a-switch>
		                        </a-form-item>
		                        <a-form-item label="价格颜色" v-if="item.isPrice">
									<kd-color v-model="item.priceColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="购买按钮">
		                            <a-switch v-model:checked="item.isPaybtn"></a-switch>
		                        </a-form-item>
		                        <a-form-item label="按钮样式" v-if="item.isPaybtn && item.listType != 3">
									<a-radio-group v-model:value="item.btnstyle">
										<a-radio :value="1">样式一</a-radio>
										<a-radio :value="2">样式二</a-radio>
										<a-radio :value="3">样式三</a-radio>
										<a-radio :value="4">样式四</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="按钮颜色" v-if="item.isPaybtn">
									<kd-color v-model="item.btnColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="商品角标">
		                            <a-switch v-model:checked="item.isMarker"></a-switch>
		                        </a-form-item>
		                        <a-form-item label="角标样式" v-if="item.isMarker">
									<a-radio-group v-model:value="item.markerstyle">
										<a-radio :value="1">新品</a-radio>
										<a-radio :value="2">热卖</a-radio>
										<a-radio :value="3">NEW</a-radio>
										<a-radio :value="4">HOT</a-radio>
										<a-radio :value="5">自定义</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="自定义角标文字" v-if="item.markerstyle == 5 && item.isMarker">
		                            <a-input v-model:value="item.markertext"></a-input>
		                        </a-form-item>
								<a-form-item label="展示商品">
									<a-radio-group v-model:value="item.goodstype">
										<a-radio :value="1">推荐商品</a-radio>
										<a-radio :value="2">自定义选择商品</a-radio>
									</a-radio-group>
								</a-form-item>
		                        <a-form-item label="展示个数" v-if="item.goodstype == 1">
		                             <a-input v-model:value="item.limit"></a-input>
		                        </a-form-item>
								<a-form-item label="排序方式">
									<a-radio-group v-model="item.goods_sort">
										<a-radio value="1">销量正序</a-radio>
										<a-radio value="2">销量倒叙</a-radio>
										<a-radio value="3">价格正序</a-radio>
										<a-radio value="4">价格倒叙</a-radio>
										<a-radio value="5">添加时间倒叙</a-radio>
										<a-radio value="6">热门倒叙</a-radio>
										<a-radio value="7">默认</a-radio>
										<a-radio value="8">点击率倒叙</a-radio>
										<a-radio value="9">收藏数倒叙</a-radio>
										<a-radio value="10">好评率倒叙</a-radio>
									</a-radio-group>
								</a-form-item>
								
								<div class="data-list" v-if="item.goodstype == 2">
								    <div class="data-item" v-for="(val,ind) in item.list" :key="ind">
										<div class="item-img">
											<img :src="val.cover" alt="">
											<div class="txt">
												<com-select-table-list data-type='goods' :data-index="ind" @getData="getTableData">
													选择商品
												</com-select-table-list>
											</div>
										</div>
										<div class="input-item">
											<a-input v-model:value="val.goods_name" addon-before="商品名称" disabled> </a-input>
											<a-input v-model:value="val.price" addon-before="单价" addon-after="元" disabled> </a-input>
										</div>
										<i class="ri-close-circle-line item-close" @click="deleteList(ind,'确认删除该商品信息吗？')"></i>
									</div>
								    <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
								</div>
		                    </a-form>
		                </div>
		
		                <!-- 优惠券 -->
		                <div v-if="'coupon'==currentType && item.id==currentId">
		                    <div class="commonpent-title">优惠券</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="标题文字">
		                            <a-input v-model:value="item.title" ></a-input>
		                        </a-form-item>
		                        <a-form-item label="更多">
		                            <a-switch v-model:checked="item.is_more" ></a-switch>
		                        </a-form-item>
		                        <a-form-item label="排列样式">
									<a-radio-group v-model:value="item.couponarray">
										<a-radio :value="1">大图模式</a-radio>
										<a-radio v-if="item.couponcard != 3" :value="2">一行两个</a-radio>
										<a-radio :value="3">一行三个</a-radio>
										<a-radio :value="4">横向滑动</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="卡片样式">
									<a-radio-group v-model:value="item.couponcard">
										<a-radio :value="1">样式一</a-radio>
										<a-radio :value="2">样式二</a-radio>
										<a-radio :value="3">样式三</a-radio>
										<a-radio :value="4">样式四</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <a-form-item label="线颜色" v-if="item.couponcard == 2">
									<kd-color v-model="item.lineColor"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="背景颜色">
									<a-radio-group v-model:value="item.couponcolor">
										<a-radio :value="1">渐变</a-radio>
										<a-radio :value="2">纯色</a-radio>
									</a-radio-group>
		                        </a-form-item>
		                        <div class="data-list">
									<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
										<template #item="{element,index}">
											<div class="data-item">
												<div class="item-img">
													<div style="width:100%;height: 100%;" :style="item.couponcolor == 1?couponBg(element.bg1,element.bg2):couponBgcolor(element.bg2)"></div>
													<div class="txt">
														<com-select-table-list data-type='coupon' :data-index="index" @getData="getTableData">
															选择优惠券
														</com-select-table-list>
													</div>
												</div>
												<div class="input-item">
													<a-input class="mb12" placeholder="优惠券" v-model:value="element.coupon_name"></a-input>
													<div class="mb12" v-if="item.couponcolor == 1 && item.couponcard != 3">
														<kd-color v-model="element.bg1" ></kd-color>
													</div>
													<div class="mb12" v-if="item.couponcard != 3">
														<kd-color v-model="element.bg2" ></kd-color>
													</div>
												</div>
												<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该优惠券吗？')"></i>
											</div>
										</template>
									</draggable>
		                            
		                            <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                        </div>
		                    </a-form>
		                </div>
		
		                <!-- 资讯 -->
		                <div v-if="'information'== currentType && item.id==currentId">
		                    <div class="commonpent-title">资讯</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="图标" >
									<kd-img-select :src="item.icon_url" @change="e=>item.icon_url = e"></kd-img-select>
		                        </a-form-item>
		                        <a-form-item label="标题一" >
		                            <a-input v-model:value="item.title_one" maxlength="2"></a-input>
		                        </a-form-item>
		                        <a-form-item label="标题一背景">
									<kd-color v-model="item.title_one_color"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="标题二">
		                            <a-input v-model:value="item.title_two" maxlength="2"></a-input>
		                        </a-form-item>
		                        <a-form-item label="标题二背景">
									<kd-color v-model="item.title_two_color"></kd-color>
		                        </a-form-item>
		                        <div class="data-list">
									<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
										<template #item="{element,index}">
											<div class="data-item">
												<div class="item-img">
													<img :src="element.cover" :alt="element.cover">
													<div class="txt">
														<com-select-table-list data-type='information' :data-index="index" @getData="getTableData">
															选择资讯
														</com-select-table-list>
													</div>
												</div>
												<div class="input-item">
													<a-input v-model:value="element.title" readonly="" addon-before="标题"></a-input>
												</div>
												<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该资讯吗？')"></i>
											</div>
										</template>
									</draggable>
		                            <div class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                        </div>
		                    </a-form>
		                </div>
		
		                <!-- 店铺展示 -->
		                <div v-if="'kd-store' == currentType && item.id == currentId">
		                    <div class="commonpent-title">店铺展示</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="标题文字">
		                            <a-input v-model:value="item.title"></a-input>
		                        </a-form-item>
		                        <a-form-item label="更多">
		                            <a-switch v-model:checked="item.is_more" ></a-switch>
		                        </a-form-item>
		                        <a-form-item label="按钮">
									<a-radio-group v-model:value="item.is_care">
										<a-radio value="1">收藏</a-radio>
										<a-radio value="2">进店逛逛</a-radio>
										<a-radio value="3">无</a-radio>
									</a-radio-group>
		                        </a-form-item>
								<a-form-item label="内容列表">
									<a-radio-group v-model:value="item.store_style">
										<a-radio value="1">商品</a-radio>
										<a-radio value="2">店铺</a-radio>
										<a-radio value="3">商品+店铺</a-radio>
									</a-radio-group>
								</a-form-item>
		                        <a-form-item label="选中颜色">
									<kd-color v-model="item.active_color"></kd-color>
		                        </a-form-item>
		                        <a-form-item label="店铺展示数量">
		                            <a-input v-model:value="item.limit" type="number"></a-input>
		                        </a-form-item>
		                        <a-form-item label="温馨提示">
		                            <span style="font-size: 12px;color: #868686;">商品展示为排序（正序）前面3个商品！</span>
		                        </a-form-item>
		                    </a-form>
		                </div>
		
		                <!-- 商品卡片 -->
		                <div v-if="'kd-goods-card' == currentType && item.id == currentId">
		                    <div class="commonpent-title">商品卡片</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="背景" >
									<kd-img-select :src="item.bg" @change="e=>item.bg = e"></kd-img-select>
		                        </a-form-item>
		                        <a-form-item label="标题文字">
		                            <a-input v-model:value="item.title"></a-input>
		                        </a-form-item>
		                        <a-form-item label="温馨提示">
		                            <span style="color: #868686;font-size: 12px;">此组件每次最多添加三个商品！</span>
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element,index}">
										<div class="data-item">
											<div class="item-img">
												<img :src="element.cover" alt="">
												<div class="txt">
													<com-select-table-list data-type='goods' :data-index="index" @getData="getTableData">
														选择商品
													</com-select-table-list>
												</div>
											</div>
											<div class="input-item">
												<a-input v-model:value="element.goods_name" addon-before="商品名称"> </a-input>
												<a-input v-model:value="element.price" addon-before="单价" addon-after="元"> </a-input>
											</div>
											<i class="ri-close-circle-line item-close" @click="deleteList(index,'确认删除该商品信息吗？')"></i>
										</div>
									</template>
								</draggable>
		                        
		                        <div v-if="item.list.length<3" class="add-item" @click="editList(item.id)"><i class="el-icon-plus"></i>添加一个</div>
		                    </div>
		                </div>
		
		                <!-- 辅助组件一 -->
		                <div v-if="'kd-assist-one' == currentType && item.id == currentId">
		                    <div class="commonpent-title">辅助组件</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="标题一">
		                            <a-input v-model:value="item.title_one"></a-input>
		                        </a-form-item>
		                        <a-form-item label="标题二">
		                            <a-input v-model:value="item.title_two"></a-input>
		                        </a-form-item>
		                        <a-form-item label="温馨提示">
		                            <span style="color: #868686;font-size: 12px;">监控数据根据排序（正序）依次获取！</span>
		                        </a-form-item>
		                    </a-form>
		                    <div class="data-list">
								<draggable v-model="item.list" item-key="index" animation="500" :component-data="{name:'fade'}">
									<template #item="{element}">
										<div class="data-item">
											<div class="item-img">
												<div style="width:100%;height: 100%;" :style="{background:element.bg}"></div>
											</div>
											<div class="input-item">
												<div>
													<span>背景颜色：</span>
													<kd-color v-model="element.bg"></kd-color>
												</div>
												<a-input v-model:value="element.name" addon-before="文字表述"> </a-input>
												<div class="mb12">
													<com-select-link v-model="element.path" @changeJump="e=>element.jump = e"></com-select-link>
												</div>
												<span v-if="element.jump == 2">
													<a-input v-model:value="element.appid" placeholder="小程序appid"></a-input>
													<a-input v-model:value="element.mini_path" placeholder="小程序打开页面"></a-input>
												</span>
												<span v-if="element.jump == 3">
													<a-input v-model:value="element.path" placeholder="电话号码"></a-input>
												</span>
											</div>
										</div>
									</template>
								</draggable>
		                    </div>
		                </div>
		                <!-- 视频展示 -->
		                <div v-if="'kd-video'==currentType&& item.id==currentId">
		                    <div class="commonpent-title">视频展示</div>
		                    <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
		                        <a-form-item label="视频封面">
									<kd-img-select :src="item.cover" @change="e => item.cover =e "></kd-img-select>
		                        </a-form-item>
		                        <a-form-item label="视频播放地址">
		                            <a-input v-model:value="item.url"></a-input>
		                        </a-form-item>
		                    </a-form>
		                </div>
		            
						<!-- 热区 -->
						<div v-if="'kd-hotarea' == currentType && item.id == currentId">
							<div class="commonpent-title">热区</div>
							<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
							    <a-form-item>
									<template #label>
										<span>热区编辑</span>
										<a-tooltip title="打开此开关可进行热区编辑,热区选择完成之后,关闭此开关可进行热区跳转选择">
											<i class="el-icon-info"></i>
										</a-tooltip>
									</template>
							          <a-switch v-model:checked="show.hotAreaEdit"></a-switch>
							    </a-form-item>
								
								<a-form-item label="热区图片">
									<kd-img-select :src="item.cover" @change="e=>item.cover=e"></kd-img-select>
								</a-form-item>
								
								<a-form-item label="热区高度">
									<a-slider v-model:value="item.height" :min="20" :max="2000" show-input></a-slider>
							    </a-form-item>
								<div class="kd-hotarea-item" v-for="(val,ind) in item.list" :key="ind">
									<a-form-item :label="'热区'+(ind+1)">
										<div class="mb12">
											<com-select-link v-model="val.path" @changeJump="e=>val.jump = e"></com-select-link>
										</div>
										<span v-if="val.jump == 2">
											<a-input v-model:value="val.appid" placeholder="小程序appid"></a-input>
											<a-input v-model:value="val.mini_path" placeholder="小程序打开页面"></a-input>
										</span>
										<span v-if="val.jump == 3">
											<a-input v-model:value="val.path" placeholder="电话号码"></a-input>
										</span>
									</a-form-item>
									 <i class="ri-delete-bin-line" @click="deleteList(ind,'确认删除该热区吗？')"></i>
								</div>
							</a-form>
						</div>

						<div v-if="'kd-device' == currentType && item.id== currentId">
							<div class="commonpent-title">设备</div>
							<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
							    <a-form-item label="选择设备">
									<com-device-select :value="item.did" @change="(e)=>{item.did = e}"></com-device-select>
							    </a-form-item>
							    <a-form-item label="样式">
									<a-radio-group v-model:value="item.card">
										<a-radio :value="true">通栏</a-radio>
							        	<a-radio :value="false">卡片</a-radio>
									</a-radio-group>
							        
							    </a-form-item>
							</a-form>
						</div>

						<div v-if="'kd-popup-advert' == currentType && item.id==currentId">
							<div class="commonpent-title">弹出层</div>
							<a-form :label-col="{span:6}" :wrapper-col="{span:16}">
								<a-form-item label="弹框图片">
									<kd-img-select :src="item.cover" @change="e=>item.cover=e"></kd-img-select>
								</a-form-item>
								<a-form-item label="链接地址">
									<div class="mb12">
										<com-select-link v-model="item.path" @changeJump="e=>item.jump = e"></com-select-link>
									</div>
									<div v-if="item.jump == 2">
										<a-input v-model:value="item.appid" placeholder="小程序appid"></a-input>
										<a-input v-model:value="item.mini_path" placeholder="小程序打开页面"></a-input>
									</div>
									<span v-if="item.jump == 3">
										<a-input v-model:value="item.path" placeholder="电话号码"></a-input>
									</span>
								</a-form-item>
								<a-form-item label="预览" v-if="item.cover">
									<a-switch v-model:checked="show.popupAdvert" @change='e=>show.popupAdvertData = item'></a-switch>
								</a-form-item>
							</a-form>
							
						</div>	
					
					</div>
		        <!-- </GeminiScrollbar> -->
		    </div>
		</div>
	
	</div>
</template>

<script>
// 引入拖拽组件
const elId = document.getElementById("activeWrapper")
import draggable from 'vuedraggable'
import modelItem from '@/components/layout/customPage/model-item.vue'
import customStore from '@/components/layout/customPage/custom-store.vue'
import customCoupon from '@/components/layout/customPage/custom-coupon.vue'
import customHotArea from '@/components/layout/customPage/custom-hot-area.vue'
import customGoods from '@/components/layout/customPage/custom-goods.vue'
import customHomeTop from '@/components/layout/customPage/custom-home-top.vue'
import kdColor from '@/components/public/kd-color.vue'
import comReminxiIcon from '@/components/public/com-reminxi-icon.vue'
import comSelectLink from '@/components/miniapp/com-select-link.vue'
import comDeviceSelect from '@/components/miniapp/com-device-select.vue'
import comSelectTableList from '@/components/miniapp/com-select-table-list.vue'
import { computed, nextTick, reactive, toRefs ,ref} from 'vue'
import { useRoute } from 'vue-router'
import customPage from '@/api/customPage.js'
import tool from '@/util/tool.js'
export default {
	components:{
		kdColor,
		draggable,
		customGoods,
		modelItem,
		customStore,
		customCoupon,
		customHotArea,
		comReminxiIcon,
		customHomeTop,
		comSelectLink,
		comSelectTableList,
		comDeviceSelect
	},
	setup(){
		
		const myPage = ref(null)
		const _d = reactive({
			pageTitle:{
				title:"首页",
				bgColor:"#fff",
				fontColor:"#000",
				is_show_nav:false,
				isNavBack:false
			},
			id:0,
			type: 1,
			is_default:0,
			list:[],
			currentId:0,
			marginEdit:0,
			currentType:'',
			currentListIndex:0,
			is_show_table: false,
			show:{
				video:false,
				hotAreaEdit:false,
				popupAdvert:false,
				popupAdvertData:null,
			},
			screenHeight: window.innerHeight,
		})

		const couponBg = ()=>computed(()=>{
			return function(color1,color2){
				return `background:linear-gradient(to bottom, ${color1},  ${color2});`
			}
		})

		const couponBgcolor = ()=>computed(()=>{
			return function(color2){
				return `background:${color2};`
			}
		})

		const opt = useRoute().query
		if( opt.id ) customPage.getCustomPageDetail(opt.id,res=>{
			_d.id = opt.id
			_d.list =JSON.parse( res.page_value )
			_d.pageTitle = {
				title:res ? res.page_name :'首页',
				bgColor:res ? res.background_color : "#fff",
				fontColor:res ? res.text_color :"#000",
				is_show_nav:res ? res.is_show_nav :false,
				isNavBack:res ? res.isNavBack :false
			}
			_d.is_default = res.is_default
			_d.is_show_table = res.is_show_table == 1 ? true : false
		})

		window.onresize = () => {
			return (() => {
			_d.screenHeight = window.innerHeight
			})()
		}

		function selectCurrentType(e,currentId){
			_d.currentId = currentId
			_d.list.map((item,index)=>{
				if( item.id == currentId ){
					_d.currentType = item.type
				}
			})
		}

		function addElement(type){
			let timeId = new Date().getTime();
			customPage.addElement(_d.list,timeId,type,res=>{})
			nextTick(()=>{
				let el = document.querySelector(".activeWrapper")
				el.scrollTop = el.scrollHeight;
			})
		}

		function showDeleteWrapper(id){
			tool.confirm("确认删除该组件元素吗？").then(()=>{
				_d.list.map((item,index)=>{
					if( item.id == id ){
						_d.list.splice(index,1)
					}
				})
			}).catch(()=>{})
		}

		function editList(id){
			_d.list.map((item,index)=>{
				if( item.id == id ){
					switch (_d.currentType){
						case 'kd-home-top':
							_d.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								path: '',
								appid:'',
								jump:1,
							})
							break;
						case 'slide':
							_d.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								path: '',
								appid:'',
								jump:1,
							})
							break;
						case 'navigation':
							_d.list[index].list.push({
								title: '按钮文字1',
								url: 'https://kundian.cqkundian.com/images/17/2019/01/UCVU3TG2IPTvq4z0SNy1a5giGzsZpx.png',
								path: '',
								appid:'',
								jump:1,
							})
							break;
						case 'advert':
							_d.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								width: 50,
								path: '',
								appid:'',
								jump:1,
							})
							break;
						case 'glossarys':
							_d.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								title: '标题',
								innerText: '内容',
								path: '',
								appid:'',
								jump:1,
							})
							break;
						case 'photo':
							_d.list[index].list.push({
								url: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								title: '我是标题',
								path: '',
								appid:'',
								jump:1,
							})
							break;
						case 'goods':
							_d.list[index].list.push({
								id:0,
								cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								goods_name: '这里是商品标题(商品从设定商品中读取)',
								price: '20.00',
								goods_remark:'商品简单说明',
								sale_count:0,
								true_sale:0
							})
							break;

						case 'information':
							_d.list[index].list.push({
								id:0,
								cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								title: '这里是资讯信息标题'
							})
							break;
						case 'coupon':
							_d.list[index].list.push({
								id:0,
								low_cash_price: 0,
								coupon_price: 0,
								coupon_name: '优惠券名称',
								bg1:'#c38dff',
								bg2:'#4f53ff',
								begin_time:'11月10日',
								end_time:'11月11日'
							})
							break;
						case 'kd-goods-card':
							_d.list[index].list.push({
								id:0,
								cover: 'https://we.cqkundian.com/addons/kundian_farm/resource/home/images/goods-3.jpg',
								goods_name: '这里是商品标题(商品从设定商品中读取)',
								price: '20.00',
							})
							break;
						default:
							break;
					}
				}
			})
		}

		function deleteList(ind,title){
			_d.list.map((item,index)=>{
				if( item.id == _d.currentId ){
					if( !title ){
						title = '确认删除吗？'
					}
					tool.confirm(title).then(res=>{
						_d.list[index].list.splice(ind,1)
					}).catch(()=>{})
				}
			})
		}

		function getTableData(e){
			let {list,currentType} = _d 
			list.map((item,index)=>{
				if( item.id == _d.currentId ){
					item.list.map((val,ind)=>{
						if( ind == e.index ){
							if(currentType == 'goods' || currentType == 'kd-goods-card'){
								list[index].list[ind] = e.value
							}
							if( currentType == 'coupon' ){
								list[index].list[ind].id = e.value.id
								list[index].list[ind].coupon_name = e.value.coupon_name
								list[index].list[ind].coupon_price = e.value.coupon_price
								list[index].list[ind].low_cash_price = e.value.low_cash_price
								list[index].list[ind].type = e.value.type
							}
							if( currentType == 'information' ){
								list[index].list[ind] = e.value
							}
						}
					})
				}
			})
			_d.list = list
		}

		function saveHomePage(is_default){
			let { list,id ,is_show_table,pageTitle} = _d
			let data ={
				is_default,
				is_show_nav:pageTitle.is_show_nav? 1 : 0 ,
				is_show_table: is_show_table? 1: 0,
				page_type:1,
				page_value:list,
				page_name:pageTitle.title,
				text_color:pageTitle.fontColor,
				background_color:pageTitle.bgColor,
				isNavBack:pageTitle.isNavBack
			}
			customPage.addOrEditCustomPage(data,id,res=>{
				_d.id = res.id
			})
		}

		function changeAdvert(index){
			let { list } = _d ,arr = []
				
			if( list[index].selectType == 1 ){	//样式1  一张图片
				arr = customPage.changeAdvert(t,1)
			}
			if( list[index].selectType == 2 ){  //样式2 两张图片
				arr = customPage.changeAdvert(t,2)
			}
			if( list[index].selectType == 3 ){  //样式3 三张图片
				arr = customPage.changeAdvert(t,3)
			}
			if( list[index].selectType == 4 ){  //样式4 四张图片
				arr = customPage.changeAdvert(t,4)
			}
			if( list[index].selectType == 5 ){  //样式5 五张图片
				arr = customPage.changeAdvert(t,4)
			}
			_d.list[index].list = arr
		}

		function changePhotoColimn(index){
			let { list } = _d
			_d.list[index].list = customPage.changePhotoColumn(t,list[index].column)
		}

		const changeCurrentItem = (e) => _d.currentId = e
		const option = (e) => _d.type = e
		
		return {
			myPage,
			...toRefs(_d),
			couponBg,
			couponBgcolor,
			selectCurrentType,
			showDeleteWrapper,
			editList,
			deleteList,
			getTableData,
			saveHomePage,
			changeCurrentItem,
			option,
			changePhotoColimn,
			changeAdvert,
			addElement
		}
	},
}
</script>
<style lang="scss">
    body {
        margin: 0;
        padding: 0;
    }
	#storePage{
        margin: 0;
	}
	.custom-storepage{
	    display: flex;
	    width: 100%;
	    flex-wrap: nowrap;
	    justify-content: space-between;
	    overflow: hidden;
		background: #f1f5f6;
	}

	// 选项
	.storepage-option{
	    background: #fff;
	    display: flex;
	    text-align: center;
	    padding-top: 30px;

	    &-title{
	        font-size: 15px;
	        width: 100px;
	        line-height: 36px;

	        >div{
	            cursor: pointer;
	        }

	        >div:hover{
	            color: #409EFF;
	        }

	        .optionactive{
	            color: #409EFF;
	        }
	    }


	}

	// 组件列表
	.basic-components{
		width: 250px;
		border: 1px solid #F4F4F4;
		height: 660px;
		float: left;
		margin-right: 20px;

		&-title{
			width: 100%;
			height: 40px;
			line-height: 40px;
			background: #F4F4F4;
			text-align: center;
		}

		&-ul{
			width: 100%;
			display: flex;
			flex-wrap: wrap;

			&-li{
				width:33.3%;
				height: 70px;
				text-align: center;
				font-size: 12px;
				cursor: pointer;

				img{
					margin-top: 17px;
					width: 30px;
				}
			}

			&-li:hover{
				background: #f4f4f4;
			}
		}
	}

	.none-element{
		text-align: center;
		font-size: 12px;
		color: gray;
		line-height: 200px;
	}
	.mianWrapper{
	    width: 375px;
	    border: 1px solid #e5e5e5;
	    position: relative;
	    background: #fff;

		.wxHd{
			width: 100%;
			margin: 0px auto;
			height: 22px;
			position: relative;
			background-image: url('../../../../assets/img/wxhd_01.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;

			div{
			    font-size: 16px;
			    text-align: center;
			    width: 60%;
			    line-height: 40px;
			    position: absolute;
			    left: 20%;
			    top: 30px;
			    color: #333;
			}
		}
	}
	.activeWrapper_li {
	    position: relative;
	    text-align: center;
	    border: 1px dashed #fff;
	}
	.activeBorder {
	    border: 1px dashed #1A97EE;
	}
	li {
	    list-style: none;
	    padding-left: 0;
	}
	li, ul {
	    padding: 0;
	    list-style: none;
		margin: 0;
	}

	.scroll-bar{
	    height: 692px;
        overflow-x: hidden;
	}

	.edditWrapper {
	    min-height: 140px;
	    width: 400px;
	    box-sizing: border-box;
	    margin-left: 20px;
	    margin-top: 20px;
	    background: #fff;
	    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		overflow: hidden;
		overflow-y: auto;

	    .banner_container{
	        padding: 0 15px 0 10px;
	    }

		.el-input-group{
			width: 100% !important;
		}

        .el-slider__button-wrapper{
            height: 25px;
            width: 25px;
            top: -10px;
        }
	}
	.data-item{
		width: 94%;
		border: 1px solid #f4f4f4;
		border-radius: 10px;
		display: flex;
	    align-items: center;
		padding: 10px 10px;
		cursor: move;
		margin-bottom: 10px;
	}

	.data-item:hover{
		border: 2px dashed #1A97EE;
	}

	.data-item .item-img{
		width: 80px;
		height: 80px;
		border: 1px solid #f4f4f4;
		margin-right: 10px;
	}
	.item-img img{
		width: 80px;
		height: 80px;
	}
	.input-item{
		width: 75%;
		height: 100%;
	}
	.input-item .el-input{
		margin-bottom: 10px;
	}
	.item-close{
		position: relative;
		top: -6px;
		left: 6px;
		font-size: 20px;
		color: #868686;
		cursor: pointer;
	}
	.item-img .txt{
		height: 20px;
		left: 0;
		right: 0;
		background: rgba(0,0,0,0.5);
		color: #fff;
		text-align: center;
		font-size: 12px;
		line-height: 20px;
		position: relative;
		cursor: pointer;
		top: -24px;
	}
	.add-item{
		width: 100%;
		height: 30px;
		border: 1px solid #f4f4f4;
		text-align: center;
		font-size: 14px;
		line-height: 34px;
		color: #868686;
		cursor: pointer;
	}
	.activeWrapper {
	    width: 100%;
	    height: 590px;
	    margin: 0 auto 0px;
	    overflow-y: auto;
	    position: relative;
		overflow-x: hidden;
	}
	.activeWrapper::-webkit-scrollbar {display:none}

	.activeWrapper_li{
	    position: relative;
	    text-align: center;
	    border: 1px dashed #fff;
	}

	.activeWrapper_li:hover{
	    border: 1px dashed #409EFF;
	}

	.activeBorder{
	    border: 1px dashed #409EFF;
	}
	.activeWrapper_li:hover{
	    cursor: pointer;
	}
	.activeWrapper_li:hover .deleteDom{
	    display: block !important;
	}
	.deleteDom{
		position: absolute;
		bottom: 0px;
		right: 1px;
		z-index: 9999;
		display: none !important;
		cursor: pointer;
		color: #409EFF;
		font-size: 25px;
	}
	.footer-menu-btn{
		display: flex;
        height: 40px;
        background: #1B2C54;
        width: 100%;
        line-height: 40px;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
	}

	.commonpent-title{
		width: 90%;
		height: 40px;
		line-height: 40px;
		color: #424040;
		font-size: 14px;
		font-weight: bold;
		margin-left: 4%;
	}

	//
	.markerstyle_img{
	    width: 100px;
	    height: 100px;
	    border: 2px dashed #eee;
	    text-align: center;
	    cursor: pointer;

	    >img{
	        width: 100px;
	        height: 100px;
	    }

	    .ri-add-line{
	        font-size: 2rem;
	    }

	    >div>p:last-child{
	        margin-top: -30px;
	    }
	}

	.markerstyle_tips{
	    margin-top: 10px;
	    color: red;
	}
	
	.kd-hotarea-item{
		display: flex;
		i{
			position: relative;
			top: 6px;
			left: 9px;
			cursor: pointer;
			color: #999;
		}
	}

	 //弹出层广告
    .pre-popup-advert{
        .pa-mask{
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
			position: absolute;
        }
        .pa-img{
            width: 100%;
			position: absolute;
			z-index: 999;
			top: 50%;
			transform: translateY(-50%);
        }
		.pa-close{
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid #fff;
			position: absolute;
			z-index: 999;
			left: 50%;
			bottom: 6%;
			line-height: 24px;
			text-align: center;
			color: #fff;

			i{
				position: relative;
				top: 2px;
			}
		}
    }
</style>
